<template>
  <div>
    <b-overlay :show="isLoading || isSaving">

      <b-alert
        show
        variant="info"
      >
        <div class="alert-body">
          <p>
            Let the mentIQ portal automatically check whether a backup or replication job for a client ran on a particular day of the week or not. To be able to check this, a reference list is needed. You can create and manage this list yourself.
          </p>
          <p>
            Use the predefined template for this purpose. This also contains a description with examples of how you must maintain the list.
            After completion of the list, please upload it on this page.
          </p>
          <p>
            If no job was running for a client on the selected days of the week, you will see purple status icons in the backup / replication matrix.
          </p>
        </div>

        <b-button
          size="sm"
          class="m-1"
          href="/assets/avamar_referencelist_template.xlsx"
        >
          <feather-icon
            icon="DownloadIcon"
            class="mr-1"
          />
          <span class="align-middle">Download Template</span>
        </b-button>
      </b-alert>
      <b-row>
        <b-col>
          <b-card title="Upload reference list">
            <b-form-file
              v-model="file"
              placeholder="Choose .csv file or drop it here..."
              drop-placeholder="Drop file here..."
              accept=".csv"
            />

            <b-button
              variant="primary"
              class="mt-1"
              :disabled="!file"
              @click="upload"
            >
              Upload
            </b-button>
          </b-card>
        </b-col>
        <b-col>
          <b-card>
            <b-card-header>
              <b-card-title>Current references</b-card-title>
              <b-button-group>
                <b-button
                  variant="primary"
                  size="sm"
                  :to="{ name: 'reference-list.avamar.cross-check'}"
                >
                  <feather-icon
                    icon="CommandIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Cross Check</span>

                </b-button>

                <b-button
                  variant="danger"
                  size="sm"
                  @click="clearReferences"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Clear references</span>

                </b-button>
              </b-button-group>
            </b-card-header>
            <b-card-body>
              <div class="table-responsive">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Node</th>
                      <th>Client</th>
                      <th>Policy</th>
                      <th>Monday</th>
                      <th>Tuesday</th>
                      <th>Wednesday</th>
                      <th>Thursday</th>
                      <th>Friday</th>
                      <th>Saturday</th>
                      <th>Sunday</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(reference, index) in shouldRunReferences"
                      :key="index"
                    >
                      <td>{{ reference.type | referenceTypeName }}</td>
                      <td>{{ reference.hostname }}</td>
                      <td>{{ reference.client }}</td>
                      <td>{{ reference.policy }}</td>
                      <td>
                        <b-form-checkbox
                          :checked="reference.monday"
                          disabled
                        />
                      </td>
                      <td>
                        <b-form-checkbox
                          :checked="reference.tuesday"
                          disabled
                        />
                      </td><td>
                        <b-form-checkbox
                          :checked="reference.wednesday"
                          disabled
                        />
                      </td><td>
                        <b-form-checkbox
                          :checked="reference.thursday"
                          disabled
                        />
                      </td><td>
                        <b-form-checkbox
                          :checked="reference.friday"
                          disabled
                        />
                      </td><td>
                        <b-form-checkbox
                          :checked="reference.saturday"
                          disabled
                        />
                      </td><td>
                        <b-form-checkbox
                          :checked="reference.sunday"
                          disabled
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>

import {
  BRow, BCol, BFormFile, BOverlay, BFormCheckbox, BCard, BCardHeader, BCardTitle, BCardBody, BAlert, BButton, BButtonGroup,
} from 'bootstrap-vue'
import AvamarService from '@/service/avamar.service'

export default {
  components: {
    BRow,
    BCol,
    BFormFile,
    BOverlay,
    BFormCheckbox,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BAlert,
    BButton,
    BButtonGroup,
  },
  filters: {
    referenceTypeName: val => {
      if (val === 0) return 'Backup'
      if (val === 1) return 'Replication'

      return ''
    },
  },
  data() {
    return {
      file: null,
      shouldRunReferences: [],
      isLoading: false,
      isSaving: false,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    clearReferences() {
      this.$swal({
        title: 'Are you sure?',
        text: 'Do you really want to delete all references? Please make sure you have a backup of your reference list, in case you want to use it again.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.isSaving = true
          AvamarService.deleteShouldRunReferenceListAsync()
            .then(() => {
              this.$toast.success('Reference list cleared.', {
                icon: true,
              })
              this.loadData()
            },
            err => {
              this.$swal({
                title: 'Something went wrong!',
                text: err.response.data.error.message,
                icon: 'error',
              })
            })
            .finally(() => {
              this.isSaving = false
            })
        }
      })
    },
    upload() {
      if (!this.file) {
        return
      }

      this.isSaving = true
      AvamarService.updateReferenceListFileAsync(this.file)
        .then(
          // eslint-disable-next-line no-unused-vars
          result => {
            this.$toast.success('Reference list uploaded.', {
              icon: true,
            })

            this.$swal({
              title: 'Reference list uploaded',
              text: 'Please note that it may take some time for the changes to be seen on the systems.',
              icon: 'success',
            })

            this.file = null
            this.loadData()
          },
          err => {
            this.$swal({
              title: 'Something went wrong!',
              text: err.response.data.error.message,
              icon: 'error',
            })
          },
        )
        .finally(() => {
          this.isSaving = false
        })
    },
    loadData() {
      this.isLoading = true
      AvamarService.getShouldRunReferenceListAsync()
        .then(result => {
          this.shouldRunReferences = result
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}

</script>
